<template>
  <div>
    <div class="row">
      <div class="flex xs12">
        <va-collapse withBackground>
          <span slot="header">{{ $t('layout.filters.label') }}</span>
          <template slot="body">
            <filters
              :loading="loading"
              :actions="tableFilters"
              @filter="filterData"
              @clear-filters="filterData(null)"
            />
          </template>
        </va-collapse>
      </div>
    </div>
    <va-card :title="label">
      <div class="row">
        <retry-notification
          :show="error"
          @retry="reload()"
        />
      </div>

      <tabs-container
        :tabs="tabs"
        :hide-single="!tabbed"
      >
        <template v-slot:pending>
          <remote-table
            class="table-hover table-striped full-table"
            :columns="tableFieldsPending"
            :data="tableData.pending"
            :loading="isLoading.pending"
            :search="searchOptions"
            :pagination="pagination.pending"
            :params="serverParams"
            :queries="serverParams.queries"
            :sortField="sort.field"
            :sortType="sort.type"
            :top-options="topButtons"
            :action-options="pendingButtons"
            :crud-links="crudLinks"
            :controller="controller"
            @update-data="updatePendingData"
            @search-data="searchPendingByName"
            @delete-item="tryDelete"
          />
        </template>
        <template v-slot:approved>
          <remote-table
            class="table-hover table-striped full-table"
            :columns="tableFieldsApproved"
            :data="tableData.approved"
            :loading="isLoading.approved"
            :search="searchOptions"
            :pagination="pagination.approved"
            :params="serverParams"
            :queries="serverParams.queries"
            :sortField="sort.field"
            :sortType="sort.type"
            :top-options="[]"
            :action-options="actionButtons"
            :crud-links="crudLinks"
            :controller="controller"
            @update-data="updateApprovedData"
            @search-data="searchApprovedByName"
            @delete-item="tryDelete"
          />
        </template>
      </tabs-container>
    </va-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const Filters = () => import(/* webpackPrefetch: true */ '@/components/extras/Filters')
const TabsContainer = () => import(/* webpackPrefetch: true */ '@/components/extras/TabsContainer')
const RemoteTable = () => import(/* webpackPrefetch: true */ '@/components/extras/DataTables/RemoteTable')

export default {
  name: 'report-summary-index',
  components: {
    Filters,
    TabsContainer,
    RemoteTable,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    crudLinks: {
      type: String,
      required: true,
    },
    controller: {
      type: String,
      default: '',
    },
    topButtons: {
      type: Array,
      default: function () {
        return []
      },
    },
    actionButtons: {
      type: Array,
      default: function () {
        return ['view', 'edit', 'delete']
      },
    },
    tableFilters: {
      type: Array,
      default: function () {
        return ['regions', 'countries', 'districts', 'dateSince', 'dateUntil']
      },
    },
    tableFields: {
      type: Array,
      default: function () {
        return [
          {
            name: 'proyection_date',
            title: this.$t('tables.headings.date'),
            callback: this.dateLabel,
            sortField: 'proyection_date',
          },
          {
            name: 'team.code',
            title: this.$t('tables.headings.code'),
            sortField: 'Teams.code',
          },
          {
            name: 'district.country.name',
            title: this.$t('tables.headings.country'),
            visible: this.currentUser.can('Countries', 'index'),
            sortField: 'Countries.name',
            callback: this.translatedName,
          },
          {
            name: 'district.name',
            title: this.$t('tables.headings.district'),
            sortField: 'Districts.name',
            callback: this.translatedName,
          },
          {
            name: 'approval',
            title: this.$t('tables.headings.district_approved'),
            callback: this.approvalLabel,
            sortField: 'approval',
          },
          {
            name: '__slot:actions',
            visible: this.tableData.pending.length > 0,
            dataClass: 'text-right',
            width: '20%',
          },
        ]
      },
    },
    reportsRoute: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    tabbed: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      error: false,
      tableData: {
        pending: [],
        approved: [],
        unapproved: [],
      },
      pagination: {
        pending: {},
        approved: {},
        unapproved: {},
      },
      searchQuery: '',
      isLoading: {
        pending: false,
        approved: false,
        unapproved: false,
      },
      pendingButtons: ['view', 'edit', 'delete'],
      searchOptions: {
        enabled: true,
        trigger: 'enter',
        placeholder: this.$t('tables.actions.search_by_date'),
        // externalQuery: searchQuery
      },
      sort: {
        field: '',
        type: 'desc',
      },
      serverParams: {
        queries: '',
      },
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    tableFieldsApproved () {
      return this.getTableFields(this.tableData.approved)
    },
    tableFieldsUnapproved () {
      return this.getTableFields(this.tableData.unapproved)
    },
    tableFieldsPending () {
      return this.getTableFields(this.tableData.pending)
    },
    tabs () {
      const t = [
        { title: this.$t('tables.status.unreviewed'), name: 'pending' },
        { title: this.$t('tables.status.approved'), name: 'approved' },
        // { title: this.$t('tables.status.rejected'), name: 'unapproved' },
      ]
      if (this.tabbed) {
        // t.unshift({ title: this.$t('reports.active'), name: 'active' })
      }
      return t
    },
    currentDate () {
      return this.$date.format(new Date(), 'yyyy-MM-dd')
    },
  },
  methods: {
    translatedName (name) {
      return this.$t(name)
    },
    filterData (filters) {
      if (!filters) {
        this.serverParams.queries = ''
        return
      }

      let queries = ''
      if (filters.region) {
        queries += '&region=' + filters.region
      }
      if (filters.country) {
        queries += '&country=' + filters.country
      }
      if (filters.district) {
        queries += '&district=' + filters.district
      }
      if (filters.team) {
        queries += '&team=' + filters.team
      }
      if (filters.dateSince) {
        queries += '&date_since=' + filters.dateSince
      }
      if (filters.dateUntil) {
        queries += '&date_until=' + filters.dateUntil
      }

      this.serverParams.queries = queries
    },
    getTableFields (tableData) {
      const fields = this.tableFields.slice(0)
      fields.push({
        name: '__slot:actions',
        visible: tableData.length > 0,
        dataClass: 'text-right',
        width: '20%',
      })
      return fields
    },
    approvalLabel (approval) {
      let a = 'tables.status.unreviewed'
      switch (approval) {
        case 1: a = 'tables.status.approved'
          break
        case -1: a = 'tables.status.rejected'
          break
      }

      return this.$t(a)
    },
    dateLabel (date) {
      return this.$date.format(date, 'MMMM - yyyy')
    },
    fixData (data) {
      // let noDef = this.$t('tables.undefined')
      for (const d of data) {
        if (!d.team) {
          d.team = {
            code: '',
          }
        }
        if (!d.district) {
          d.district = {
            name: '',
          }
        }
        if (!d.country) {
          d.country = {
            name: '',
          }
        }
        if (!d.region) {
          d.region = {
            name: '',
          }
        }
        if (!d.district.country) {
          d.district.country = {
            name: '',
          }
        }
      }
      return data
    },
    apiUrl (params) {
      let route = this.reportsRoute
      route += '?page=' + params.page || 0
      route += '&limit=' + params.perPage || 50
      if (params.sort && params.sort.field !== '') {
        let field = params.sort.field
        switch (field) {
          case 'team.code':
            field = 'Teams.code'
            break
          case 'district.name':
            field = 'Districts.name'
            break
          case 'district.country.name':
            field = 'Countries.name'
            break
          case 'country.name':
            field = 'Countries.name'
            break
          case 'region.name':
            field = 'Regions.name'
            break
        }
        route += '&sort=' + field
        route += '&direction=' + params.sort.type
      }
      if (params.queries) {
        route += params.queries
      }

      return route
    },
    reload () {
      this.updatePendingData()
      this.updateApprovedData()
      // this.updateUnapprovedData()
    },
    updatePendingData (params) {
      this.updateData(params, 'pending', `active=${this.currentDate}`)
    },
    updateApprovedData (params) {
      this.updateData(params, 'approved')
    },
    updateUnapprovedData (params) {
      this.updateData(params, 'unapproved')
    },
    searchPendingByName (name) {
      this.searchByName(name, 'pending')
    },
    searchApprovedByName (name) {
      this.searchByName(name, 'approved')
    },
    searchUnapprovedByName (name) {
      this.searchByName(name, 'unapproved')
    },
    async updateData (params, status, q = '') {
      this.isLoading[status] = true
      this.loadingError = false
      params = params || this.serverParams
      const url = this.apiUrl(params)

      let approval = 0
      switch (status) {
        case 'approved': approval = 1; break
        case 'unapproved': approval = -1; break
      }
      let response = false
      try {
        response = await this.$http.get(`${url}&approved=${approval}&${q}`)
      } catch (err) {
        this.showToast(this.$t('notifications.network.error'), {
          icon: 'fa-times',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
        this.isLoading[status] = false
        this.loadingError = true
        return
      }

      this.tableData[status] = this.fixData(response.data.data)
      this.pagination[status] = response.data.pagination
      this.isLoading[status] = false
    },
    async searchByName (name, status) {
      if (name === '') return this.reload()
      this.isLoading[status] = true

      let approval = 0
      switch (status) {
        case 'approved': approval = 1; break
        case 'unapproved': approval = -1; break
      }
      let response = false
      try {
        response = await this.$http.get(`${this.reportsRoute}?q=${name}&approved=${approval}`)
      } catch (err) {
        // console.log('Error searching report', err)
        this.isLoading[status] = false
        return
      }

      this.tableData[status] = this.fixData(response.data.data)
      this.pagination[status] = response.data.pagination
      this.isLoading[status] = false
    },
    async tryDelete (item) {
      const result = await this.$swal({
        icon: 'warning',
        text: this.$t('notifications.confirm.delete'),
        showCancelButton: true,
        confirmButtonText: this.$t('layout.buttons.confirm'),
        cancelButtonText: this.$t('layout.buttons.cancel'),
      })
      if (result.value !== true) return

      this.loading = true
      try {
        await this.$http.delete(`${this.reportsRoute}/${item.id}`)
      } catch (err) {
        // console.log('Error deleting report', err)
        this.loading = false
        return
      }
      this.reload()
    },
  },
}
</script>
<style scoped>
.full-table {
  width: 100%;
}
</style>
